<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6 col-5">
                        <h6><b>Order Summary</b></h6>
                    </div>
                    <div class="col-lg-6 col-7">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/cart">cart</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Order Summary</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div v-if="product_list.length > 0">

                    <div class="modal fade mt-5" id="truckModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog mt-5">
                            <div class="modal-content shadow-sm">
                                <div class="modal-header">
                                    <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Select Truck</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-6 mt-3">                                    
                                            <button class="btn btn-success form-control" data-bs-dismiss="modal" @click="default_truck = 5; generateSales()">5MT</button>
                                        </div>
                                        <div class="col-6 mt-3">                       
                                            <button class="btn btn-success form-control"  data-bs-dismiss="modal" @click="default_truck = 10; generateSales()">10MT</button>                                         
                                        </div>
                                        <div class="col-6 mt-3">                       
                                            <button class="btn btn-success form-control" data-bs-dismiss="modal" @click="default_truck = 15; generateSales()">15MT</button>                                         
                                        </div>
                                        <div class="col-6 mt-3">                       
                                            <button class="btn btn-success form-control" data-bs-dismiss="modal" @click="default_truck = 20; generateSales()">20MT</button>                                         
                                        </div>
                                        <div class="col-6 mt-3">                       
                                            <button class="btn btn-success form-control" data-bs-dismiss="modal" @click="default_truck = 30; generateSales()">30MT</button>                                         
                                        </div>
                                        <div class="col-6 mt-3">                       
                                            <button class="btn btn-success form-control" data-bs-dismiss="modal" @click="default_truck = 45; generateSales()">45MT</button>                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade mt-5" id="truckModalFMN" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog mt-5">
                            <div class="modal-content shadow-sm">
                                <div class="modal-header">
                                    <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Select Truck</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-6 mt-3">                       
                                            <button class="btn btn-success form-control" data-bs-dismiss="modal" @click="default_truck = 30; generateSales()">30MT</button>                                         
                                        </div>
                                        <div class="col-6 mt-3">                       
                                            <button class="btn btn-success form-control" data-bs-dismiss="modal" @click="default_truck = 40; generateSales()">40MT</button>                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- edit cart -->
                    <div class="row">
                        <div class="col-12 col-sm-6 mt-3">
                            <span class="alert alert-success p-2 font-12 form-control mb-1" style="background: #006e40;color:#fff">Usable Balance: <b> &#x20A6;{{numTH(account_balance - this.proforma_sum)}}</b></span> 
                            <span class="alert alert-warning p-2 font-12 form-control mb-1">Available Balance: <b>&#x20A6;{{numTH(account_balance)}}</b></span>
                        </div>
                        <div class="col-12 col-sm-6 text-end mt-3" v-if="deliveryMode == 'self'">
                            <button type="button" style="    margin-right: 5px;" class="btn bg-success btn-sm text-white bolder font-12" data-bs-toggle="modal" data-bs-target="#truckModal">Change Truck Capacity</button>

                            <a href="/cart" class="btn bg-scope btn-sm text-white bolder font-12">EDIT CART</a>
                        </div>
                        <div class="col-12 col-sm-6 text-end mt-3" v-if="deliveryMode !== 'self'">
                            <button type="button" style="margin-right: 5px;" class="btn bg-success btn-sm text-white bolder font-12" data-bs-toggle="modal" data-bs-target="#truckModalFMN">Change Truck Capacity</button>

                            <a href="/cart" class="btn bg-scope btn-sm text-white bolder font-12">EDIT CART</a>
                        </div>
                        
                        <!-- <div class="col-12 mt-3">
                            <h4 class="sub-name">Total Order Size: {{Math.ceil(totalTons)}} Tonne{{totalTons > 1 ? 's':''}}</h4>
                        </div> -->
                    </div>


                    <div class="mt-1"  v-for="(truck,i) in sales_data" :key="i">
                        <div class="p-2 shadow-sm border bg-white">

                            <!-- cart listings -->
                            <div class="row mt-3">
                                <div class="col-12">
                                    <u style="padding-left: 15px;"><b>Order {{i+1}}</b></u>
                                </div>

                                <div class="col-lg-4 mb-"  v-for="(x, n) in truck" :key="n + '' + i">
                                    <div class="card-body d-flex">
                                        <div class="p-1">
                                            <img :src="x.product.image" style="width: 50px;" v-if="x.product.image" />   
                                            <img src="https://www.freeiconspng.com/uploads/no-image-icon-6.png" style="width: 50px;" v-else />
                                        </div>

                                        <div class="ml-5">
                                            <b>{{x.product.productname}}</b> <br>
                                            <small class="font-12">
                                                
                                                <p style="font-size:1px">{{x.qty = Math.floor(x.newunit * x.product.bagcount)}}</p>
                                                <!-- <p style="font-size:1px">{{x.items = Number(x.newunit) * Number(x.product.bagcount)}}</p> -->

                                                {{Math.floor(x.newunit * x.product.bagcount)}}
                                                <span v-if="x.qty == 1">{{unitFormatter(x.product.unit)}}</span><span v-else>{{unitFormatter(x.product.unit)}}s</span>
                                                x <span v-if="x.product.discountamount > 0">&#x20A6;{{numTH(x.product.price - x.product.discountamount)}} &nbsp; </span>  
                                                <span :class="x.product.discountamount > 0 ? ' discount-strike text-secondary font-weight-light': ''">&#x20A6;{{numTH(x.product.price)}}</span>
                                                
                                                <br> ({{Math.ceil(x.newunit)}} Tonne{{x.newunit > 1 ? 's':''}})

                                                <br><small>Update Items in quantity</small><br>

                                                <!-- <i v-if="truck.map(y => y.newunit).reduce((p, v) => Number(p) + Number(v)) < default_truck" class="fa fa-plus" @click="x.newunit = Number(x.newunit) + 1" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                 <i v-else class="fa fa-plus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i>

                                                <input type="number" v-model="x.newunit" disabled style="width: 88px;margin: 5px">
                                                
                                                <i  v-if="x.newunit > 1" class="fa fa-minus" @click="x.newunit = Number(x.newunit) - 1" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                <i v-else class="fa fa-minus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i> -->

                                                <!-- <input type="hidden" :value="x.items = Number(x.newunit) * Number(x.product.bagcount)"> -->

                                                <i v-if="truck.map(y => y.newunit).reduce((p, v) => Number(p) + Number(v)) < default_truck" class="fas fa-plus" @click="x.qty = Number(x.qty) + 1; x.newunit = x.qty/x.product.bagcount" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                <i v-else class="fas fa-plus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i>

                                                <input type="number" @input="x.newunit = x.qty/x.product.bagcount" disabled v-model="x.qty" style="width: 88px;margin: 5px">

                                                <i  v-if="x.newunit > 1" class="fa fa-minus" @click="x.qty = Number(x.qty) - 1; x.newunit = x.qty/x.product.bagcount" style="background:green; color:#fff; padding:10px; border-radius:50px"></i>
                                                <i v-else class="fa fa-minus" style="background:#000; color:#fff; padding:10px; border-radius:50px"></i>


                                            </small>
                                            <!--  <br><span class="font-12 bolder">&#x20A6;{{numTH(x.product.price * x.qty)}}</span> -->
                                        </div>
                                    </div>                              
                                </div>
                            </div>

                            <div class="row mt-4">
                                
                                <div class="col-lg-6 offset-lg-5">
                                    <table class="table table-hover">
                                        <tbody>
                                            <tr>
                                                <td>Order Size:</td>
                                                <td style="text-align:right; width:3px"></td>
                                                <td style="text-align:right; width:10px">
                                                    <b>
                                                       {{Math.ceil(truck.map(y => y.newunit).reduce((p, v) => Number(p) + Number(v)))}} Tonnes
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr v-if="deliveryMode=='self'">
                                                <td>Recommended Truck Size:</td>
                                                <td style="text-align:right; width:3px"></td>
                                                <td style="text-align:right; width:10px">
                                                    <b>
                                                       {{ minTruck(truck.map(y => y.newunit).reduce( (p, v) => Number(p) + Number(v) ))}} Tonnes
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Subtotal:</td>
                                                <td style="text-align:right; width:3px">
                                                    &#x20A6;
                                                </td>
                                                <td style="text-align:right; width:10px">
                                                    <b>
                                                       {{numTH(truck.map(x => (x.product.price - x.product.discountamount) * x.qty).reduce( (p, v) => Number(p) + Number(v) ))}}
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr v-if="deliveryMode != 'self'">
                                                <td>Freighted Cost:</td>
                                                <td style="text-align:right; width:3px">
                                                    <span v-if="deliveryMode != 'self' && ((truck.map(y => y.product.applyfreight).reduce((p, v) => Number(p) + Number(v)) == 0))">
                                                        <span v-if="truck.map(y => y.product.freefreight_region).reduce((p, v) => p +','+ v).toLowerCase().split(',').includes(deliveryRegion) != true">
                                                            &#x20A6;
                                                        </span>                                                    
                                                    </span>
                                                </td>
                                                
                                                <td style="text-align:right">
                                                    <b v-if="deliveryMode != 'self' && truck.map(y => y.product.applyfreight).reduce( (p, v) => Number(p) + Number(v)) == 0">
                                                        <span v-if="truck.map(y => y.product.freefreight_region).reduce((p, v) => p +','+ v).toLowerCase().split(',').includes(deliveryRegion) != true">
                                                            {{  
                                                            thDFormat( 
                                                                (delivery_charges_server[i] = deliveryCharges(minTruck(truck.map(y => y.newunit).reduce( (p, v) => Number(p) + Number(v) )))) 
                                                                )                                                           
                                                            }}
                                                        </span>
                                                        <b v-else>Free</b>                                                       
                                                    </b>
                                                    <b v-else>Free</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Total Payable:</td>
                                                <td style="text-align:right; width:3px">
                                                    &#x20A6;
                                                </td>
                                                <td style="text-align:right">
                                                    <b v-if="deliveryMode != 'self'">  
                                                        <!--                                                        -->

                                                        <span v-if="truck.map(y => y.product.applyfreight).reduce( (p, v) => Number(p) + Number(v)) == 0">
                                                            <span v-if="truck.map(y => y.product.freefreight_region).reduce((p, v) => p +','+ v).toLowerCase().split(',').includes(deliveryRegion) != true">
                                                                {{ numTH( truck.map(x => (x.product.price - x.product.discountamount) * x.qty).reduce( (p, v) => Number(p) + Number(v)) + deliveryCharges(minTruck(truck.map(y => y.newunit).reduce( (p, v) => Number(p) + Number(v) )))) }}
                                                            </span>
                                                            <span v-else>                                                            
                                                                {{numTH( truck.map(x => (x.product.price - x.product.discountamount) * x.qty).reduce( (p, v) => Number(p) + Number(v)) )}}
                                                            </span>
                                                        </span>
                                                        <span v-else>                                                            
                                                            {{numTH( truck.map(x => (x.product.price - x.product.discountamount) * x.qty).reduce( (p, v) => Number(p) + Number(v)) )}}
                                                        </span>

                                                    </b>
                                                    <b v-if="deliveryMode == 'self'">
                                                        {{numTH( truck.map(x => (x.product.price - x.product.discountamount) * x.qty).reduce( (p, v) => Number(p) + Number(v) ) )}}
                                                    </b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>   
                                    
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12 col-sm-4 offset-sm-8">
                            <div class="mt-2 text-end">
                                <button class="btn checkout-btn form-control shadow-sm rounded" @click="completeOrder()" v-if="proforma_x_loaded ==  true && account_x_loaded == true">COMPLETE ORDER</button>
                                <button class="btn checkout-btn" v-else disabled><i class="fa fa-spinner fa-spin"></i></button>
                            </div>
                        </div>
                    </div>

                </div>
                
                <div v-if="no_cart" class="text-center p-5">
                    <img src="@/assets/images/empty-cart.png" style="width:60%" />
                    <p class="mt-5">Your cart is empty, please <a href="/products">click here</a> to add an item</p>
                </div>
                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';



export default {
    name: "Checkout",
    components: {
        SideNav, TopNav, Copyright
    },
    

    data() {
        return{
            "page_active": "orders",
            obj_product:{},
            obj_setorder:{},

            sub_acct:'',
            location:'',
            no_cart:false,

            cart_total:0,
            deliveryMode:0,
            oms_updated_total:0,
            oms_pending_total:0,


            proforma_sum:0,                 
            account_balance:0,        
            proforma_x_loaded:false,
            account_x_loaded:false,


            product_list:[],
            all_product_list:[],
            sales_data:[],
            delivery_rate:[],
            delivery_charges_server: [],
            
            default_truck:30
        }
    },


    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Checkout";
            },
        },
    },

    mounted () {
        
        this.inactivityTime(); 
        this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

        this.sub_acct = this.$store.getters.sub_acct;
        this.location = this.$store.getters.collectionarea;  
        this.deliveryMode = this.$store.getters.deliveryMode;

        if(this.deliveryMode != 'self'){
            this.fetchfreightcharges();
        }

        // this.default_truck = Number(prompt('Enter truck splitting capacity'));


        this.fetchAccountBalance();
        this.loadProduct();
        this.fetchProformaSum();

    },

    computed: {
        cartitem() {
            return this.$store.getters.cart;
        },        

        cartarray (){
            return this.$store.getters.cartdata;
        },

        sumTotal(){
            return this.product_list.reduce( (acc, product) =>  acc += product.qty * (product.price - product.discountamount)  , 0);
        },

        finalTotal(){
            return this.sumTotal;
        },

        totalTons(){
           return this.cartarray.reduce((a, b) => a + Number(b.unit), 0);
        },

        deliveryRegion(){
            let subacct = this.$store.getters.sub_acct;
            let allacct = this.$store.getters.subaccounts;
            let account_info = allacct.find( el => el.CustomerAccount == subacct ); 
            return (account_info?.CustomerPostalAddress[this.$store.getters.deliveryaddress].AddressState).toLowerCase();
        }
    },


    methods: {

        // # fn() to return the sum;
        // & of proforma order on oms;
        fetchProformaSum(){
            let params = {"sub_accountID": this.sub_acct,"subaccountID":this.sub_acct};
            let data = this.loadNew(params);

            axios({
                method: "post",
                url: this.$hostname+"/fetchproformasum",
                data: {'token':data},
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
            })

            .then(res => {
                //# confirm if loaded.
                this.proforma_x_loaded = true;

                // # store proforma sum
                if(res.data.sum.length > 0)
                    this.proforma_sum = Number(res.data.sum[0].total);
 

            })
        },


        // # fn() to return AX balance for selected account;
        // # purpose for credit limit check;
        fetchAccountBalance(){
            let data = this.loadNew({"subaccountID":this.$store.getters.vendor_no});
            axios({
                method: "get",
                url: this.$hostname+"/getsubaccountID",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    // # prepare server response
                    this.$store.commit("set_subaccounts", res.data.data);
                    let sub_acct   = this.$store.getters.sub_acct;
                    let currentacc = res.data.data.find(el => el.CustomerAccount == sub_acct);

                    this.account_x_loaded    = true; 
                    this.account_balance     = currentacc.CreditRemaining;

                    console.log(this.account_balance)

                    // store current in lDB
                    this.$store.commit("set_sub_acct_bal", this.account_balance); 
                }
            })
        },
    

        // # fn() request for delivery fee;
        // & rely on X-API;
        fetchfreightcharges() {
            try {                          
                
                let collectionCategory = this.$store.getters.collectionCategory;
                let collectionName = this.$store.getters.collectionName;
                let subacct = this.$store.getters.sub_acct;
                let allacct = this.$store.getters.subaccounts;

                let account_info = allacct.find( el => el.CustomerAccount == subacct ); 
                let destination = account_info.CustomerPostalAddress[this.$store.getters.deliveryaddress].AddressCity;
                
                console.log(params = {
                    "Destination":  destination, 
                    "siteid": collectionCategory,
                    "city": destination,
                    "subaccountID":this.$store.getters.sub_acct 
                });

                let params = {
                    "Destination":  destination, 
                    "siteid": collectionCategory,
                    "city": destination,
                    "subaccountID":this.$store.getters.sub_acct 
                };

                console.log(params)

                // # find loadNew fn purpose in documentation
                let data = this.loadNew(params);

                axios({
                    method: "get",
                    url: this.$hostname+"/fetchfreightcharges",
                    params: {'token':data},
                    headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.delivery_rate = res.data.data;
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },


        // # fn() request for all active products;
        async loadProduct() {
            try {
                this.obj_product.location     = this.location;
                this.obj_product.subaccountID = this.sub_acct;
                // this.obj_product.subaccountID = this.$store.getters.sub_acct
                
                let data = this.loadNew(this.obj_product);

                await axios({
                    method: "get",
                    url: this.$hostname+"/productCatalogueServer",
                    params:  {'token':data},
                    headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })
                .then(res => {
                    this.all_product_list = res.data.data;
                    let product_list = this.all_product_list.filter( el => this.cartitem.includes(el.itemID));

                    let key = 'itemID';

                    let arrayUniqueByKey = [...new Map(product_list.map(item =>
                    [item[key], item])).values()];

                    this.product_list = arrayUniqueByKey;


                    if(this.product_list.length == 0){
                        this.no_cart = true;
                    }
                    this.generateSales();
                    this.$swal.close();
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })  

                .finally(e => {                 
                    console.log(e);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },


        getProductDetails(id){
            return this.product_list.find( x => x.itemID == id)
        },


        // thousand figure formatting!
        numTH(x){
            return this.thDFormat(Number(x));
        },



        generateSales(){
            let all_products    = [];
            let leftover        = 0;
            let total_count     = 0;
            let space           = 0;

            this.cartarray.forEach(element => {
                let remainder = 0;

                if (all_products.length > 0){
                    let last_array =  all_products[all_products.length - 1];
                    
                    if (leftover) {
                        let last_item = last_array[last_array.length - 1];
                        
                        leftover        = Number(leftover);
                        element.unit    = Number(element.unit);
                        remainder       = (leftover + element.unit) > this.default_truck ? this.default_truck - leftover : element.unit;

                        all_products.push([
                            {
                                id: last_item.id,
                                newunit: leftover,
                                product: this.getProductDetails(last_item.id)
                            },
                            {
                                id: element.itemID,
                                newunit: remainder,
                                product: this.getProductDetails(element.itemID)
                            }
                        ])
                    }

                    let last_array_sum = last_array.map((x) => x.newunit).reduce((p, v) => Number(p) + Number(v)) 

                    space = last_array_sum > this.default_truck ? 0 : this.default_truck - last_array_sum

                    if (space) {
                        all_products[all_products.length - 1].push({
                            id: element.itemID,
                            newunit: space > element.unit ? element.unit : space,
                            product: this.getProductDetails(element.itemID)
                        })
                    }
                }

                let left = element.unit - remainder - space

                let division = parseFloat(left / this.default_truck)

                let count =  Math.floor(division)

                leftover = count > 0 ? left % this.default_truck : 0
                
                for (let i = 0; i < count; i++ ){
                    all_products.push([{
                        id : element.itemID,
                        newunit: this.default_truck,
                        product: this.getProductDetails(element.itemID)
                    }])
                }

                total_count++

                if ((this.cartarray.length == total_count && leftover > 0) || (division > 0 && division < 1)) {
                    all_products.push([{
                        id : element.itemID,
                        newunit: left % this.default_truck,
                        product: this.getProductDetails(element.itemID)
                    }])
                }               
            });


            this.sales_data = all_products;
        },


        minTruck(x){
            if(x <= 5){
                return 5
            }
            if(x <= 10){
                return 10
            }
            if(x <= 15){
                return 15
            }
            else if(x <= 20 ){
                return 20
            }
            else if(x <= 30 ){
                return 30
            }
            else if(x <= 40 ){
                return 40
            }
            else if(x <= 45 ){
                return 45
            }
            else{
                return 30
            }
        },


        unitFormatter(x){
            let lastChar = x.substr(x.length - 1)

            if(x == 'gal'){
                return 'Gallon';
            }
            else{
                if(lastChar == 's'){
                    return x.slice(0, -1);
                }
                else{
                    return x;
                }
            }            
        },


        deliveryCharges(y){

            if(this.delivery_rate.filter( (data) => data.accountRelationDesc.match(/\d+/) == y ).length > 0)
            {
                return Number(Number(this.delivery_rate.find( (data) => data.accountRelationDesc.match(/\d+/) == y )?.freightAmount)?.toFixed(2));

            }
            else{
                return 0.00;
            }

        },

        

        // fn() check if customer fund can purchase order
        // & it rely on @fetchProformaBalance();
        completeOrder(){
            if(this.proforma_x_loaded ==  true && this.account_x_loaded == true){


                let final_total = 0 // check usage!
                let available_fund = Number(this.account_balance - this.proforma_sum);


                // calculate order size
                let salesdata = this.sales_data;
                let deliverydata = this.delivery_charges_server;

                console.log(this.delivery_charges_server)

                for(let i = 0; i < salesdata.length; i++){
                    for(let j = 0; j < salesdata[i].length; j++){
                        final_total += salesdata[i][j].qty * (salesdata[i][j].product.price - salesdata[i][j].product.discountamount);
                    }
                    if(deliverydata.length > 0)
                        final_total += deliverydata[i];
                }

                // # fn() to push order to OMS when
                // it pass the credit limit check.
                if(available_fund >= final_total){
                    this.setOrders();
                }
                else{
                    this.$swal({
                        title: "<i class='fa fa-times' style='color:red;background: #ff000024;padding: 25px 34px;font-size: 50px;border-radius: 50%;'></i><br><br>Credit Alert",
                        html: "Total Order: &#x20A6;"+final_total.toLocaleString()+"<br>Usable Balance: &#x20A6;"+available_fund.toLocaleString()+"<br><br> You do not have a sufficient balance for this transaction, do you want to transfer funds from other accounts?",
                        type: "error",
                        showCancelButton: true,
                        confirmButtonColor: "#006E40",
                        confirmButtonText: "Yes, Let's go",
                        closeOnConfirm: true
                    }).then((result) => {
                        if (result.value) {                            
                            this.$router.push('/transfer-fund');
                        }
                    });
                }
            }
            else{
                this.$swal.fire(
                    'Balance Alert',
                    'Sorry, we are still processing your available balance. Please hold on!',
                    'error'
                )
            }
        },


        // fn() prepare order and push to server
        async setOrders(){
            
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});

            //collect the object
            //send the object and finalTotal to server
            //validate response - successful or failed;
            try {
                let subacct = this.$store.getters.sub_acct;
                let allacct = this.$store.getters.subaccounts;
                let account_info = allacct.filter( el => el.CustomerAccount == subacct ); 

                this.obj_setorder.fn = 'setOrders';

                // this.obj_setorder.items = this.product_list;
                // this.obj_setorder.total = this.finalTotal;
                // this.obj_setorder.selected_truck = this.$store.getters.selected_truck;

                this.obj_setorder.collectionarea = this.$store.getters.collectionarea;
                this.obj_setorder.collectionName = this.$store.getters.collectionName; // warehouse name
                this.obj_setorder.deliveryMode = this.$store.getters.deliveryMode;
                this.obj_setorder.deliveryaddress = this.$store.getters.deliveryaddress;

                this.obj_setorder.sub_acct = this.$store.getters.sub_acct;
                this.obj_setorder.salesdata = this.sales_data;
                this.obj_setorder.account = account_info;
                this.obj_setorder.deliverydata = this.delivery_charges_server;
                this.obj_setorder.subaccountID = this.$store.getters.sub_acct;
                
                this.obj_setorder.collection_category = this.$store.getters.collectionCategory;
                
                if(this.deliveryMode != 'self'){
                    this.obj_setorder.delivery_city = account_info[0].CustomerPostalAddress[this.$store.getters.deliveryaddress].AddressCity;
                    
                }

                console.log(this.obj_setorder);
                let new_obj_setorder = this.loadNew(this.obj_setorder);

                await axios({
                    method: "post",
                    // url: "https://fmnplc.website/fmn/app_data.php",
                    url: this.$hostname+"/create-order",
                    data: {'token':new_obj_setorder},
                    headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => { 
                    
                    this.$swal.close();
                    
                    this.response = res.data
                    if(this.response.status){                                   
                        // alert(this.response.message)
                         this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success',
                                3000
                            )


                        

                        this.$store.commit("updateCart", []);
                        this.$store.commit("updateCartdata", []);

                        this.product_list = []
                        this.no_cart = true;
                    }
                    else{                                                   
                        // alert(this.response.message) 
                        this.$swal.fire(
                                'Error Alert',
                                this.response.message,
                                'error'
                            )
                    }    
                    
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }   
        },
    },

};
</script>

<style scoped>
.swal2-cancel{
    background: red !important;
}
</style>